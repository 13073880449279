<template>
  <div>
    <!-- TODO: hibát kap amikor lenullázzuk cancelNoti -->
    <fp-form-modal title="Új üzenet" size="xl" v-model="visible">
      <template #content>
        <!-- Sablon hozzáadó gombok -->
        <div>
          <b-button
              v-if="!editableNotification.Email"
              @click="addEmailNotification"
              style="margin: 10px 5px"
          >
            <b-icon style="margin: 0px 5px" icon="envelope-fill"></b-icon>
            Email
          </b-button>
          <b-button
              v-if="!editableNotification.Sms"
              @click="addSMSNotification"
              style="margin: 10px 5px"
          >
            <b-icon
                style="margin: 0px 5px"
                icon="chat-square-text-fill"
            ></b-icon>
            SMS
          </b-button>
          <b-button
              v-if="!editableNotification.Push"
              @click="addPUSHNotification"
              style="margin: 10px 5px"
          >
            <b-icon style="margin: 0px 5px" icon="phone-vibrate"></b-icon>
            Push
          </b-button>
        </div>
        <!-- Email sablon -->
        <fp-panel-card
            v-if="editableNotification.Email"
            style="margin: 10px 0px"
            :title="'Email'"
        >
          <!-- Sablon típusa -->
          <template #title>
            <b-icon style="margin: 0px 5px" icon="envelope-fill"></b-icon>
            {{ $t("components.templates.email") }}
          </template>
          <!-- Sablon adatok -->
          <template #content>
            <fp-select
                :items="Object.values($enums.EmailPriority)"
                valueKey="Value"
                label="Prioritás"
                v-model="editableNotification.Email.Priority"
            >
              <template #element="e">
                {{ $t(e.Text) }}
              </template>
            </fp-select>
            <!-- Tárgy -->
            <fp-input
                :label="$t('components.templates.emailSubject')"
                v-model="editableNotification.Email.Subject"
            />
            <!-- Email szöveg -->
            <ejs-richtexteditor
                ref="richTextEditor"
                v-model="editableNotification.Email.HtmlContent"
                @change="getEmailNotificationText()"
                :toolbarSettings="toolbarSettings"
                :insertImageSettings="insertImageSettings"
                :pasteCleanupSettings="pasteCleanupSettings"
                :width="'100%'"
                :height="500"
            >
            </ejs-richtexteditor>
            <!-- <fp-input
              label="Csatolmány"
              type="file"
              @change="uploadFile"
              :multiple="true"
            />
            <div>
              <div
                v-for="(attachment, index) in editableNotification.Email
                  .Attachments"
                :key="'attachment-' + index"
              >
                {{ attachment.FileName }}
                <b-icon
                  @click="reomveAttachment(index)"
                  icon="x-circle"
                ></b-icon>
              </div>
            </div> -->
            <b-button @click="removeEmailNotification" style="margin: 10px 0px"
            >Email eltávolítás</b-button
            >
          </template>
        </fp-panel-card>
        <!-- SMS sablon -->
        <fp-panel-card
            v-if="editableNotification.Sms"
            style="margin: 10px 0px"
            :title="'SMS'"
        >
          <!-- Sablon típusa -->
          <template #title>
            <b-icon
                style="margin: 0px 5px"
                icon="chat-square-text-fill"
            ></b-icon>
            {{ $t("components.templates.sms") }}
          </template>
          <!-- Sablon adatok -->
          <template #content>
            <!-- SMS üzenet -->
            <fp-text-area
                :label="$t('components.templates.smsMessage')"
                v-model="editableNotification.Sms.Text"
            />
            <b-button @click="removeSMSNotification" style="margin: 10px 0px">
              SMS eltávolítás
            </b-button>
          </template>
        </fp-panel-card>
        <!-- PUSH sablon -->
        <fp-panel-card
            v-if="editableNotification.Push"
            style="margin: 10px 0px"
            :title="'Push'"
        >
          <!-- Sablon típusa -->
          <template #title>
            <b-icon style="margin: 0px 5px" icon="phone-vibrate"></b-icon>
            {{ $t("components.templates.push") }}
          </template>
          <!-- Sablon adatok -->
          <template #content>
            <!-- Push címe -->
            <fp-input
                :label="$t('components.templates.pushTitle')"
                v-model="editableNotification.Push.Title"
            />
            <!-- Push üzenet -->
            <fp-text-area
                :label="$t('components.templates.pushMessage')"
                v-model="editableNotification.Push.Body"
            />
            <!-- Push képe -->
            <fp-input
                :label="$t('components.templates.pushImg')"
                v-model="editableNotification.Push.ImageUrl"
            />
            <div>
              <img
                  width="200px"
                  v-if="editableNotification.Push.ImageUrl"
                  :src="editableNotification.Push.ImageUrl"
              />
            </div>
            <b-button @click="removePUSHNotification" style="margin: 10px 0px"
            >Push eltávolítás</b-button
            >
          </template>
        </fp-panel-card>
        <b-button @click="sendNotification">Küldés</b-button>
        <b-button @click="cancelNoti">Mégsem</b-button>
      </template>
    </fp-form-modal>
  </div>
</template>
<script>
import Vue from "vue";
import {
  RichTextEditorPlugin,
  Toolbar,
  Link,
  Image,
  Count,
  HtmlEditor,
  QuickToolbar,
  PasteCleanup,
  Table,
} from "@syncfusion/ej2-vue-richtexteditor";
import { NotificationLogic } from "../../../../../Logic/Backend/notification";

Vue.use(RichTextEditorPlugin);

export default {
  name: "Notifications",
  data() {
    return {
      visible: this.value,
      editableNotification: { RecipientId: this.participantId },
      defaultNotification: {
        RecipientId: this.participantId,
        Email: null,
        Sms: null,
        Push: null,
      },
      defaultEmailNotification: {
        Subject: null,
        TextContent: null,
        HtmlContent: null,
        Priority: [],
        Attachments: [],
      },
      defaultSMSNotification: { Text: null },
      defaultPUSHNotification: {
        Title: null,
        Body: null,
        ImageUrl: null,
      },
      toolbarSettings: {
        type: "MultiRow",
        enableFloating: false,
        items: [
          "Bold",
          "Italic",
          "Underline",
          "StrikeThrough",
          "FontName",
          "FontSize",
          "FontColor",
          "BackgroundColor",
          "LowerCase",
          "UpperCase",
          "SuperScript",
          "SubScript",
          "|",
          "Formats",
          "Alignments",
          "OrderedList",
          "UnorderedList",
          "Outdent",
          "Indent",
          "|",
          "CreateTable",
          "CreateLink",
          "Image",
          "|",
          "ClearFormat",
          "Print",
          /* "SourceCode", */
          "FullScreen",
          "|",
          "Undo",
          "Redo",
        ],
      },
      insertImageSettings: {
        saveFormat: "Base64",
      },
      pasteCleanupSettings: {
        prompt: true,
        plainText: false,
        keepFormat: false,
      },
      defaultAttachment: {
        MimeType: null,
        FileName: null,
        Data: null,
      },
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Boolean,
    participantId: Number,
  },
  watch: {
    value(input) {
      this.visible = input;
    },
    visible(input) {
      this.$emit("change", input);
      if (!input) {
        this.cancelNoti();
      }
    },
  },
  provide: {
    richtexteditor: [
      Toolbar,
      Link,
      Image,
      Count,
      HtmlEditor,
      QuickToolbar,
      PasteCleanup,
      Table,
    ],
  },
  methods: {
    getEmailNotificationText() {
      this.editableNotification.Email.TextContent = this.$refs.richTextEditor.ej2Instances.getText();
    },
    uploadFile(input) {
      const files = input.target.files;
      files.forEach((f) => {
        const blob = new Blob([f]);
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        const email = this.editableNotification.Email;
        reader.onloadend = function() {
          var base64data = reader.result;
          email.Attachments.push({
            MimeType: f.type,
            FileName: f.name,
            Data: base64data,
          });
        };
      });
      console.log(this.editableNotification.Email.Attachments);
    },
    addEmailNotification() {
      this.$set(
          this.editableNotification,
          "Email",
          this.defaultEmailNotification
      );
    },
    addSMSNotification() {
      this.$set(this.editableNotification, "Sms", this.defaultSMSNotification);
    },
    addPUSHNotification() {
      this.$set(
          this.editableNotification,
          "Push",
          this.defaultPUSHNotification
      );
    },
    removeEmailNotification() {
      this.$set(this.editableNotification, "Email", null);
    },
    reomveAttachment(index) {
      this.editableNotification.Email.Attachments.splice(index, 1);
    },
    removeSMSNotification() {
      this.$set(this.editableNotification, "Sms", null);
    },
    removePUSHNotification() {
      this.$set(this.editableNotification, "Push", null);
    },
    async sendNotification() {
      const sendNotificationResponse = await NotificationLogic.sendNotification(
          this.editableNotification
      );
      if (!sendNotificationResponse.Code) {
        this.$bvToast.toast("Sikeresen elküldte az üzeneteket!", {
          title: "Sikeres küldés",
          variant: "success",
          solid: true,
        });
        this.cancelNoti();
      } else {
        this.$bvToast.toast(sendNotificationResponse.Message, {
          title: this.$t("requestResponse.basic.errorSend"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    cancelNoti() {
      this.editableNotification = JSON.parse(
          JSON.stringify(this.defaultNotification)
      );
      console.log("CANCEL NOTI");
      console.log(this.editableNotification);
    },
  },
};
</script>
