<template>
  <!-- TODO: globális szótár -->
  <fp-form-modal
    :title="messages.Name"
    size="lg"
    v-if="messages"
    v-model="visible"
  >
    <template #content>
      <fp-template-by-langs
        :supportedLangs="messages.SupportedLanguages"
        :emailTemplate.sync="messages.EmailTemplate"
        :smsTemplate.sync="messages.SmsTemplate"
        :pushTemplate.sync="messages.PushTemplate"
        :editable="false"
      />
    </template>
  </fp-form-modal>
</template>
<script>
export default {
  name: "ViewMessagesModal",
  props: {
    messages: Object,
    value: Boolean,
  },
  data() {
    return {
      visible: this.value,
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  watch: {
    value(input) {
      this.visible = input;
    },
    visible(input) {
      this.$emit("change", input);
    },
  },
};
</script>
